export const IMAGE = {
    bg:require('./Images/bg.png'),
    order:require('./Images/order.png'),
    profile:require('./Images/profile.png'),
    coupons:require('./Images/coupons.png'),
    orderh:require('./Images/orderh.png'),
    rating:require('./Images/rating.png'),
    logout:require('./Images/logout.png'),
    setting:require('./Images/setting.png'),
    man:require('./Images/man.png'),
    checked:require('./Images/checked.png'),
    cancel:require('./Images/cancel.png'),
    verified:require('./Images/verified.png'),
    kyc:require('./Images/kyc.png'),



}