import React from 'react'

export default function Mobile() {
  return (
    <div
    className='h-[100vh] w- flex justify-center items-center text-3xl'
    >
      We are comming soon on your Mobile Phones
    </div>
  )
}
